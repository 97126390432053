import { createStore } from "vuex";
// import { MENU_ITEMS } from "../config/const.config";

export default createStore({
  state: {
    tagsList: [],
    collapse: JSON.parse(sessionStorage.getItem("collapse")),
    searchInfo: [
      {
        searchData: null,
        routerName: "costCenterList",
      },
      {
        searchData: null,
        routerName: "InventoryReport",
      },
      {
        searchData: null,
        routerName: "ReceiptSummaryReport",
      },
      {
        searchData: null,
        routerName: "inventoryReceiptIissueBalance",
      },
      {
        searchData: null,
        routerName: "exportreport",
      },
      {
        searchData: null,
        routerName: "outStoreDetailReport",
      },
      {
        searchData: null,
        routerName: "MaterialDailyInventoryList",
      },
      {
        searchData: null,
        routerName: "sysuserlist",
      },
      {
        searchData: null,
        routerName: "loglist",
      },
      {
        searchData: null,
        routerName: "PurMallLogList",
      },
      {
        searchData: null,
        routerName: "undoNpiDataList",
      },
      {
        searchData: null,
        routerName: "locationlist",
      },
      {
        searchData: null,
        routerName: "supplierlist",
      },
      {
        searchData: null,
        routerName: "materiallist",
      },
      {
        searchData: null,
        routerName: "contractlist",
      },
      {
        searchData: null,
        routerName: "longmateriallist",
      },
      {
        searchData: null,
        routerName: "stagnantmaterialList",
      },
      {
        searchData: null,
        routerName: "stagnantMaterialApplicationList",
      },
      {
        searchData: null,
        routerName: "stagnantMaterialApplicationDetail",
      },
      {
        searchData: null,
        routerName: "InventoryReportList",
      },
      {
        searchData: null,
        routerName: "InventorySummaryList",
      },
      {
        searchData: null,
        routerName: "orderlist",
      },
      {
        searchData: null,
        routerName: "toorderlist",
      },
      {
        searchData: null,
        routerName: "pickinglist",
      },
      {
        searchData: null,
        routerName: "userList",
      },
      {
        searchData: null,
        routerName: "pickingtodeliverylist",
      },
      {
        searchData: null,
        routerName: "receiverlist",
      },
      {
        searchData: null,
        routerName: "returngoodslist",
      },
      {
        searchData: null,
        routerName: "allocationlist",
      },
      {
        searchData: null,
        routerName: "materialreturnlist",
      },
      {
        searchData: null,
        routerName: "lnventorylist",
      },
      {
        searchData: null,
        routerName: "stockTransferList",
      },
      {
        searchData: null,
        routerName: "rfidList"
      },
      {
        searchData: null,
        routerName: "srreturnlist",
      },
      {
        searchData: null,
        routerName: "allocationdeliverylist",
      },
      {
        searchData: null,
        routerName: "pickingdeliverylist",
      },
      {
        searchData: null,
        routerName: "allocationtodeliverylist",
      },
      {
        searchData: null,
        routerName: "unavailablematerialList",
      },
      {
        searchData: null,
        routerName: "noticelist",
      },
      {
        searchData: null,
        routerName: "dashboard",
      },
      {
        searchData: null,
        routerName: "keyMaterialList",
      },
      {
        searchData: null,
        routerName: "ToBeCountedList",
      },
      {
        searchData: null,
        routerName: "toAgainInventoryList",
      },
      {
        searchData: null,
        routerName: "inventoryplanList",
      },
      {
        searchData: null,
        routerName: "materialapplylist",
      },
      {
        searchData: null,
        routerName: "effectiveMaterialList",
      },
      {
        searchData: null,
        routerName: "DeliverySummaryReport",
      },
      {
        searchData: null,
        routerName: "paymentRecord",
      },
    ],
    addInfo: [
      //库位导入
      {
        addData: null,
        routerName: "importLocation",
      },
      {
        addData: null,
        routerName: "addUser",
      },
      {
        addData: null,
        routerName: "stagnantMaterialAppication",
      },
      {
        addData: null,
        routerName: "addLocation",
      },
      {
        addData: null,
        routerName: "addCarList",
      },
      {
        addData: null,
        routerName: "newAddPicking",
      },
      {
        addData: null,
        routerName: "addPicking",
      },
      {
        addData: null,
        routerName: "createOrder",
      },
      {
        addData: null,
        routerName: "splitOrder",
      },
      {
        addData: null,
        routerName: "createreturngoods",
      },
      {
        addData: null,
        routerName: "creatematerialreturn",
      },
      {
        addData: null,
        routerName: "createAllocation",
      },
      {
        addData: null,
        routerName: "createNumber",
      },
      {
        addData: null,
        routerName: "addNumber",
      },
      {
        addData: null,
        routerName: "releaseNotice",
      },
      {
        addData: null,
        routerName: "editKeyMaterial",
      },
      {
        addData: null,
        routerName: "createInventoryplan",
      },
      {
        addData: null,
        routerName: "CreateAgainInventory",
      },
      {
        addData: null,
        routerName: "InventoryplanListCreate",
      },
      {
        addData: null,
        routerName: "effectiveMaterialImport",
      },
      {
        addData: null,
        routerName: "offlineReceiving",
      },
      {
        addData: null,
        routerName: "addPaymentRecord",
      },
    ],
    modifyInfo: [
      {
        modifyData: null,
        routerName: "modifyUser",
      },
      {
        modifyData: null,
        routerName: "updateLocation",
      },
      //编辑页面
      {
        modifyData: null,
        routerName: "EditInventoryplan",
      },
      {
        modifyData: null,
        routerName: "materialmodify",
      },
      {
        modifyData: null,
        routerName: "updatePicking",
      },
      {
        modifyData: null,
        routerName: "updatePicking",
      },
      {
        modifyData: null,
        routerName: "modifymaterialreturn",
      },
      {
        modifyData: null,
        routerName: "modifyreturngoods",
      },
      {
        modifyData: null,
        routerName: "lnventoryModify",
      },
      {
        modifyData: null,
        routerName: "allocationdeliverylUpdate",
      },
      {
        modifyData: null,
        routerName: "pickingdeliveryUpdate",
      },
      {
        modifyData: null,
        routerName: "DisposeListUpdate",
      },
      {
        modifyData: null,
        routerName: "modifyAllocation",
      },
      {
        modifyData: null,
        routerName: "updateNotice",
      },
      {
        modifyData: null,
        routerName: "ModifyUnavailblematerList",
      },
      {
        modifyData: null,
        routerName: "editUnavailablematerialList",
      },
      {
        modifyData: null,
        routerName: "materialRequisitionApproval",
      },
      {
        modifyData: null,
        routerName: "materialReturnApproval",
      },
      {
        modifyData: null,
        routerName: "unavailableMaterialApproval",
      },
      {
        modifyData: null,
        routerName: "keyMaterialApproval",
      },
      {
        modifyData: null,
        routerName: "approvalOrder",
      },
      {
        modifyData: null,
        routerName: "paymentApproval",
      },
      {
        modifyData: null,
        routerName: "approvalOfTransferOrder",
      },
      {
        modifyData: null,
        routerName: "variousMaterialApproval",
      },
      {
        modifyData: null,
        routerName: "dullMaterialApproval",
      },
      {
        modifyData: null,
        routerName: "plandiffConfirmApproval",
      },
      {
        modifyData: null,
        routerName: "accountAdjustApproval",
      },
      {
        modifyData: null,
        routerName: "stagnantMaterialModify",
      },
      {
        modifyData: null,
        routerName: "EditPaymentRecord",
      },
      {
        modifyData: null,
        routerName: "createInventoryplanApproval",
      },
    ],
    detailInfo: [
      {
        detailData: null,
        routerName: "MaterialDailyInventoryDetail",
      },
      {
        detailData: null,
        routerName: "checkRoot",
      },
      {
        detailData: null,
        routerName: "supplierdetail",
      },
      {
        detailData: null,
        routerName: "detailsContract",
      },
      {
        detailData: null,
        routerName: "materialdetail",
      },
      {
        detailData: null,
        routerName: "longmaterialdetail",
      },
      {
        detailData: null,
        routerName: "todetailsOrder",
      },
      {
        detailData: null,
        routerName: "updateOrder",
      },
      {
        detailData: null,
        routerName: "detailsOrder",
      },
      {
        detailData: null,
        routerName: "detailsPicking",
      },
      {
        detailData: null,
        routerName: "receiverofinvoicedetail",
      },
      {
        detailData: null,
        routerName: "returngoodsdetail",
      },
      {
        detailData: null,
        routerName: "detailsAllocation",
      },
      {
        detailData: null,
        routerName: "materialreturndetail",
      },
      {
        detailData: null,
        routerName: "allocationdeliveryDetails",
      },
      {
        detailData: null,
        routerName: "pickingdeliveryDetails",
      },
      {
        detailData: null,
        routerName: "UnavailableItemDetails",
      },
      {
        detailData: null,
        routerName: "inventoryDetail",
      },
      {
        detailData: null,
        routerName: "materialapplydetail",
      },
      {
        detailData: null,
        routerName: "InventoryReportDetail",
      },
      {
        detailData: null,
        routerName: "dashboardDetail",
      },
      {
        detailData: null,
        routerName: "detailPaymentRecord",
      },
    ],
  },
  mutations: {
    clearStore (state) {
      state.addInfo.map((item) => {
        item.addData = null;
      });
      state.detailInfo.map((item) => {
        item.detailData = null;
      });
      state.modifyInfo.map((item) => {
        item.modifyData = null;
      });
      state.searchInfo.map((item) => {
        item.searchData = null;
      });
    },
    delTagsItem (state, data) {
      state.tagsList.splice(data.index, 1);
      state.searchInfo.map((item) => {
        if (item.routerName === data.name) {
          item.searchData = null;
        }
        return item;
      });
    },
    setTagsItem (state, data) {
      const MENU_ITEMS = JSON.parse(sessionStorage.getItem("menuInfo"));
      MENU_ITEMS &&
        MENU_ITEMS.map((item) => {
          if (item) {
            if (item.subs) {
              if (item.subs.some((sbuItem) => sbuItem.index === data.name)) {
                state.tagsList.push(data);
              }
            } else {
              if (item.index === data.name) {
                state.tagsList.push(data);
              }
            }
          }
        });
    },
    clearTags (state) {
      state.tagsList = [];
      state.searchInfo.map((item) => {
        item.searchData = null;
        return item;
      });
    },
    closeTagsOther (state, data) {
      state.tagsList = data;
      state.searchInfo.map((item) => {
        if (item.routerName !== data.name) {
          item.searchData = null;
        }
        return item;
      });
    },
    closeCurrentTag (state, data) {
      for (let i = 0, len = state.tagsList.length; i < len; i++) {
        const item = state.tagsList[i];
        if (item.path === data.$route.fullPath) {
          if (i < len - 1) {
            data.$router.push(state.tagsList[i + 1].path);
          } else if (i > 0) {
            data.$router.push(state.tagsList[i - 1].path);
          } else {
            data.$router.push("/");
          }
          state.tagsList.splice(i, 1);
          break;
        }
      }
    },
    // 侧边栏折叠
    hadndleCollapse (state, data) {
      state.collapse = data;
      // sessionStorage.setItem("collapse", data)
    },
    setSearchInfo (state, payload) {
      if (payload) {
        state.searchInfo.map((item) => {
          if (item.routerName === payload.searchInfo.routerName) {
            item.searchData = payload.searchInfo.data;
          }
          return item;
        });
      }
    },
    setAddInfo (state, payload) {
      if (payload) {
        state.addInfo.map((item) => {
          if (item.routerName === payload.addInfo.routerName) {
            item.addData = payload.addInfo.data;
          }
          return item;
        });
      }
    },
    setModifyInfo (state, payload) {
      if (payload) {
        state.modifyInfo.map((item) => {
          if (item.routerName === payload.modifyInfo.routerName) {
            item.modifyData = payload.modifyInfo.data;
          }
          return item;
        });
      }
    },
    setDetailInfo (state, payload) {
      if (payload) {
        state.detailInfo.map((item) => {
          if (item.routerName === payload.detailInfo.routerName) {
            item.detailData = payload.detailInfo.data;
          }
          return item;
        });
      }
    },
  },
  actions: {},
  modules: {},
});
